import React, { useState, useEffect } from 'react';
import { Button, Table } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getTermloanListEntity } from '../term-loan-profile/term-loan-profile.reducer';
import { getBorrowerIdList } from '../loan-repayment/loan-repayment.reducer';
import { getEntities as getBorrowerUniqueID } from '../borrower/borrower.reducer';
import { getTermloanScheduleEntity } from './term-loan-schedule.reducer';

export const TermLoanSchedule = () => {
  const dispatch = useAppDispatch();
  const [borrowerName, setBorrowerName] = useState<string>('');
  const [borrowerId, setBorrowerId] = useState<string>('');
  const [loanId, setLoanId] = useState<string>('');

  const borrowerNameList = useAppSelector(state => state.borrower?.entities);
  const borrowerIdList = useAppSelector(state => state.loanRepayment?.borrowerIdList);
  const termLoanIdList = useAppSelector(state => state.termLoanProfile?.loanIdList);
  const termLoanSchedule = useAppSelector(state => state.termLoanSchedule?.entity);

  useEffect(() => {
    dispatch(getBorrowerUniqueID({}));
  }, [dispatch]);

  const handleBorrowerName = async event => {
    const selectedBorrowerName = event.target.value;
    setBorrowerName(selectedBorrowerName);

    try {
      await dispatch(getBorrowerIdList(selectedBorrowerName));
    } catch (error) {
      console.error('Error fetching borrower ID list:', error);
    }
  };

  const handleBorrowerId = async event => {
    const selectedBorrowerId = event.target.value;
    setBorrowerId(selectedBorrowerId);
    try {
      await dispatch(getTermloanListEntity(selectedBorrowerId));
    } catch (error) {
      console.error('Error fetching loan ID list:', error);
    }
  };

  const handleLoanIdChange = event => {
    const selectedLoanId = event.target.value;
    setLoanId(selectedLoanId);
  };

  const viewLoanSchedule = async () => {
    if (loanId) {
      try {
        await dispatch(getTermloanScheduleEntity(loanId));
      } catch (error) {
        console.error('Error fetching loan schedule:', error);
      }
    } else {
      console.error('No Loan ID selected');
    }
  };

  return (
    <div className="container-fluid">
      <h2 id="general-ledger-heading" data-cy="GeneralLedgerHeading">
        Term Loan Schedule
      </h2>
      <div className="mb-3">
        <div className="d-flex mb-5 mt-5 align-items-center dropDownContainer">
          <span className="me-2 ms-1 mt-1 text-dark"><strong>Select Borrower Name: </strong></span>
          <select value={borrowerName} onChange={handleBorrowerName}>
            <option>Select Borrower</option>
            {borrowerNameList?.map((borrower, i) => (
              <option key={i} value={borrower.borrowerBusinessName}>
                {borrower.borrowerBusinessName}
              </option>
            ))}
          </select>

          <span className="me-2 ms-5 mt-1 text-dark"><strong>Select Borrower ID: </strong></span>
          <select value={borrowerId} onChange={handleBorrowerId}>
            <option>Select Borrower ID</option>
            {borrowerIdList?.map((borrower_Id, index) => (
              <option key={index} value={borrower_Id}>
                {borrower_Id}
              </option>
            ))}
          </select>

          <span className="me-2 ms-5 mt-1 text-dark"><strong>Select Loan ID: </strong></span>
          <select value={loanId} onChange={handleLoanIdChange} className="me-3">
            <option value="">Select Loan</option>
            {termLoanIdList?.map((loanId, i) => (
              <option key={i} value={loanId}>
                {loanId}
              </option>
            ))}
          </select>

          <Button className='customButton' onClick={viewLoanSchedule}>View</Button>
        </div>
      </div>

      <div className="table-responsive mainContainer mt-5">
        <div>
          {termLoanSchedule && termLoanSchedule.length>0 ? (
            <Table responsive className="table-striped table-bordered">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Loan Id</th>
                  <th>Installment</th>
                  <th>Due Date</th>
                  <th>Principal Balance Opening</th>
                  <th>Principal Expected</th>
                  <th>Principal Balance Closing</th>
                  <th>Interest Expected</th>
                  <th>Interest Days</th>
                  <th>Billing Days Adjustment</th>
                  <th>Fees Expected</th>
                  <th>Penalty Expected</th>
                  <th>Total Expected</th>
                  <th>Principal Paid</th>
                  <th>Interest Paid</th>
                  <th>Fee Paid</th>
                  <th>Penalty Paid</th>
                  <th>Total Paid</th>
                  <th>Paid Date And Time</th>
                  <th>Principal Due</th>
                  <th>Interest Due</th>
                  <th>Fee Due</th>
                  <th>Penalty Due</th>
                  <th>Total Due</th>
                  <th>State</th>
                  <th>Type of Payment</th>
                  <th>Ad Hoc Fee</th>
                  <th>Legal Collection Fee</th>
                  <th>Exit Fee</th>
                </tr>
              </thead>
              <tbody>
                {termLoanSchedule.map((termLoan, index) => (
                  <tr key={index}>
                  <td>{termLoan.id ? termLoan.id : 0}</td>
                  <td>{termLoan.loanId ? termLoan.loanId : 0}</td>
                  <td>{termLoan.instalment ? termLoan.instalment : 0}</td>
                  <td>{termLoan.dueDate ? termLoan.dueDate : 0}</td>
                  <td>{termLoan.principalBalanceOpening ? termLoan.principalBalanceOpening : 0}</td>
                  <td>{termLoan.principalExpected ? termLoan.principalExpected : 0}</td>
                  <td>{termLoan.principalBalanceClosing ? termLoan.principalBalanceClosing : 0}</td>
                  <td>{termLoan.interestExpected ? termLoan.interestExpected : 0}</td>
                  <td>{termLoan.interestDays ? termLoan.interestDays : 0}</td>
                  <td>{termLoan.billingDaysAdjustment ? termLoan.billingDaysAdjustment : 0}</td>
                  <td>{termLoan.feesExpected ? termLoan.feesExpected : 0}</td>
                  <td>{termLoan.penaltyExpected ? termLoan.penaltyExpected : 0}</td>
                  <td>{termLoan.totalExpected ? termLoan.totalExpected : 0}</td>
                  <td>{termLoan.principalPaid ? termLoan.principalPaid : 0}</td>
                  <td>{termLoan.interestPaid ? termLoan.interestPaid : 0}</td>
                  <td>{termLoan.feePaid ? termLoan.feePaid : 0}</td>
                  <td>{termLoan.penaltyPaid ? termLoan.penaltyPaid : 0}</td>
                  <td>{termLoan.totalPaid ? termLoan.totalPaid : 0}</td>
                  <td>{termLoan.paidDateTime ? termLoan.paidDateTime :"00/00/0000"}</td>
                  <td>{termLoan.principalDue ? termLoan.principalDue : 0}</td>
                  <td>{termLoan.interestDue ? termLoan.interestDue : 0}</td>
                  <td>{termLoan.feeDue ? termLoan.feeDue : 0}</td>
                  <td>{termLoan.penaltyDue ? termLoan.penaltyDue : 0}</td>
                  <td>{termLoan.totalDue ? termLoan.totalDue : 0}</td>
                  <td>{termLoan.state ? termLoan.state : 0}</td>
                  <td>{termLoan.typeOfPayment ? termLoan.typeOfPayment : 0}</td>
                  <td>{termLoan.adHocFee ? termLoan.adHocFee : 0}</td>
                  <td>{termLoan.legalCollectionFee ? termLoan.legalCollectionFee : 0}</td>
                  <td>{termLoan.exitFee ? termLoan.exitFee : 0}</td>
                </tr>
                
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="alert alert-warning showContainer">No Records found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TermLoanSchedule;
