import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getTermloanListEntity, getTermloanProfileEntity } from './term-loan-profile.reducer';
import { getBorrowerIdList } from '../loan-repayment/loan-repayment.reducer';
import { getEntities as getBorrowerUniqueID } from '../borrower/borrower.reducer';
import NumberFormat from 'react-number-format';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { TextFormat } from 'react-jhipster';

export const TermLoanProfile = () => {
  const dispatch = useAppDispatch();
  const [borrowerName, setBorrowerName] = useState<string>('');
  const [borrowerId, setBorrowerId] = useState<string>('');
  const [loanId, setLoanId] = useState<string>('');

  const borrowerNameList = useAppSelector(state => state.borrower?.entities);
  const borrowerIdList = useAppSelector(state => state.loanRepayment?.borrowerIdList);
  const termLoanIdList = useAppSelector(state => state.termLoanProfile?.loanIdList);
  const termLoanProfile = useAppSelector(state => state.termLoanProfile?.entity);

  useEffect(() => {
    dispatch(getBorrowerUniqueID({}));
  }, [dispatch]);

  const handleBorrowerName = async event => {
    const selectedBorrowerName = event.target.value;
    setBorrowerName(selectedBorrowerName);

    try {
      await dispatch(getBorrowerIdList(selectedBorrowerName));
    } catch (error) {
      console.error('Error fetching borrower ID list:', error);
    }
  };

  const handleBorrowerId = async event => {
    const selectedBorrowerId = event.target.value;
    setBorrowerId(selectedBorrowerId);
    try {
      await dispatch(getTermloanListEntity(selectedBorrowerId));
    } catch (error) {
      console.error('Error fetching borrower ID list:', error);
    }
  };

  const handleLoanIdChange = event => {
    const selectedLoanId = event.target.value;
    setLoanId(selectedLoanId);
  };

  const viewLoanProfile = async () => {
    if (loanId) {
      try {
        await dispatch(getTermloanProfileEntity(loanId));
      } catch (error) {
        console.error('Error fetching loan profile:', error);
      }
    } else {
      console.error('No Loan ID selected');
    }
  };

  return (
    <div className="container-fluid">
      <h2 id="general-ledger-heading" data-cy="GeneralLedgerHeading">
        Term Loan Profile
      </h2>
      <div className="mb-3">
        <div className="d-flex mb-5 mt-5 align-items-center dropDownContainer">
          <span className="me-2 ms-1 mt-1 text-dark"><strong>Select Borrower Name: </strong></span>
          <select value={borrowerName} onChange={handleBorrowerName}>
            <option>Select Borrower</option>
            {borrowerNameList?.map((borrower, i) => (
              <option key={i} value={borrower.borrowerBusinessName}>
                {borrower.borrowerBusinessName}
              </option>
            ))}
          </select>

          <span className="me-2 ms-5 mt-1 text-dark"><strong>Select Borrower ID: </strong></span>
          <select value={borrowerId} onChange={handleBorrowerId}>
            <option>Select Borrower ID</option>
            {borrowerIdList?.map((borrower_Id, index) => (
              <option key={index} value={borrower_Id}>
                {borrower_Id}
              </option>
            ))}
          </select>

          <span className="me-2 ms-5 mt-1 text-dark"><strong>Select Loan ID: </strong></span>
          <select value={loanId} onChange={handleLoanIdChange} className="me-3">
            <option value="">Select Loan</option>
            {termLoanIdList && termLoanIdList.length > 0 ? (
              termLoanIdList.map((loanid, index) => (
                <option key={index} value={loanid}>
                  {loanid}
                </option>
              ))
            ) : null}
          </select>
          <Button className='customButton' onClick={viewLoanProfile}>View</Button>
        </div>
      </div>
      <div>
        {termLoanProfile && Object.keys(termLoanProfile).length > 0 ? (
          <div className="table-responsive mainContainer mt-5">
            <Row>
              <Col>
                <div className="mb-3 mt-5 border border-2 p-4">
                  <span className="mb-3">
                    <h4>Profile statement</h4>
                  </span>
                  <div>
                    <div className="mt-3">
                      <span className="mb-3"> <strong>Loan Application Unique ID : </strong>{termLoanProfile.loan_application_unique_id ? termLoanProfile.loan_application_unique_id : 0}
                      </span>
                      <div className="mt-3">
                        <span> <strong>Borrower ID : </strong>{termLoanProfile?.borrower_id}</span>
                      </div>

                      <div className="mt-3">
                        <span className="mb-3"><strong> Borrower Name : </strong>{termLoanProfile?.borrower_name}</span>
                      </div>
                    </div>
                    <div className="mt-3">
                      <span className="mb-3"><strong>Loan Activation Date : </strong>{termLoanProfile?.loan_activation_date ? (
                        <TextFormat type="date" value={termLoanProfile?.loan_activation_date} format={APP_DATE_FORMAT} />
                      ) : null} </span>
                    </div>
                    <div className="mt-3">
                      <span className="mt-5"><strong> Report Generation Date: </strong> {termLoanProfile?.report_generated_date ? (
                        <TextFormat type="date" value={termLoanProfile?.report_generated_date} format={APP_DATE_FORMAT} />
                      ) : null}</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col></Col>
            </Row>
            <Table responsive className="table-striped table-bordered tableDesign">
              <tbody>
                <tr>
                  <th scope="row">Old Mambu Loan Id</th>
                  <td>{termLoanProfile.old_mambu_loan_id ? termLoanProfile.old_mambu_loan_id : 0}</td>
                </tr>
                <tr>
                  <th scope="row">Net Loan Amount</th>
                  <td>
                    <NumberFormat
                      value={termLoanProfile.net_loan_amount !== null ? termLoanProfile.net_loan_amount : 0}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Origination Fee</th>
                  <td>
                    <NumberFormat
                      value={termLoanProfile.origination_fee_amount !== null ? termLoanProfile.origination_fee_amount : 0}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Total Gross Loan Amount</th>
                  <td>
                    <NumberFormat
                      value={termLoanProfile.total_gross_loan_amount !== null ? termLoanProfile.total_gross_loan_amount : 0}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Interest Rate</th>
                  <td>{termLoanProfile.interest_rate ? termLoanProfile.interest_rate : '0'}%</td>
                </tr>
                <tr>
                  <th scope="row">Installments</th>
                  <td>{termLoanProfile.instalments ? termLoanProfile.instalments : 0}</td>
                </tr>
                <tr>
                  <th scope="row">First Installment Date</th>
                  <td>{termLoanProfile.first_instalment_date ? termLoanProfile.first_instalment_date : '00/00/0000'}</td>
                </tr>
                <tr>
                  <th scope="row">Servicing Fee Rate</th>
                  <td>{termLoanProfile.servicing_fee_rate ? termLoanProfile.servicing_fee_rate : 0}</td>
                </tr>
                <tr>
                  <th scope="row">Servicing Fee Amount</th>
                  <td>
                    <NumberFormat
                      value={termLoanProfile.servicing_fee_amount !== null ? termLoanProfile.servicing_fee_amount : 0}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Loan Creation Date</th>
                  <td>{termLoanProfile.loan_creation_date ? termLoanProfile.loan_creation_date : '00/00/0000'}</td>
                </tr>

                <tr>
                  <th scope="row">Loan Status</th>
                  <td>{termLoanProfile.loan_status ? termLoanProfile.loan_status : 0}</td>
                </tr>
                <tr>
                  <th scope="row">Repayment Status</th>
                  <td>{termLoanProfile.repayment_status ? termLoanProfile.repayment_status : 0}</td>
                </tr>
                <tr>
                  <th scope="row">Funder ID</th>
                  <td>{termLoanProfile.funder_id ? termLoanProfile.funder_id : 0}</td>
                </tr>
                <tr>
                  <th scope="row">Fixed Monthly Repayment</th>
                  <td>
                    <NumberFormat
                      value={termLoanProfile.fixed_monthly_repayment !== null ? termLoanProfile.fixed_monthly_repayment : 0}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Fixed Monthly Debit</th>
                  <td>
                    <NumberFormat
                      value={termLoanProfile.fixed_monthly_direct_debit !== null ? termLoanProfile.fixed_monthly_direct_debit : 0}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Loan Completion Date</th>
                  <td>{termLoanProfile.loan_completion_date ? termLoanProfile.loan_completion_date : '00/00/0000'}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="alert alert-warning showContainer">No Records found</div>
        )}
      </div>

    </div>
  );
};

export default TermLoanProfile;
