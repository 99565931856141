import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { loadMoreDataWhenScrolled, parseHeaderForLinks } from 'react-jhipster';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ILoanProfile, defaultValue } from 'app/shared/model/loan-profile.model';

const initialState: EntityState<ILoanProfile> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: [],
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  selectedStatus: '',
}; 

const TermLoanScheduleUrl = `${SERVER_API_URL}core-banking/flender/term-loan/term-loan-schedule`;
 
 
export const getTermloanScheduleEntity = createAsyncThunk(
  'termloanSchedule/fetch_entity',
  async (loanId: any) => {   
    const requestUrl = `${TermLoanScheduleUrl}?loanId=${loanId}`;
    const response = await axios.get<ILoanProfile>(requestUrl);
    return response.data; 
  },
  { serializeError: serializeAxiosError }
);

export const TermloanScheduleSlice = createEntitySlice({
  name: 'termloanSchedule',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getTermloanScheduleEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload;
      })
      .addMatcher(isFulfilled(getTermloanScheduleEntity), (state, action) => {
        state.entity = action.payload;
      })
      .addMatcher(isPending(getTermloanScheduleEntity), state => {
        state.errorMessage = null;
        state.loading = true;
      });
  },
});

export const { reset } = TermloanScheduleSlice.actions;

export default TermloanScheduleSlice.reducer;
