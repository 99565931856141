import funder from 'app/entities/funder/funder.reducer';
import loanType from 'app/entities/loan-type/loan-type.reducer';
import loanProduct from 'app/entities/loan-product/loan-product.reducer';
import loan from 'app/entities/loan/loan.reducer';
import loanFunder from 'app/entities/loan-funder/loan-funder.reducer';
import loanDisbursement from 'app/entities/loan-disbursement/loan-disbursement.reducer';
import loanRepayment from 'app/entities/loan-repayment/loan-repayment.reducer';
import borrower from 'app/entities/borrower/borrower.reducer';
import generalLedger from 'app/entities/general-ledger/general-ledger.reducer';
import funderDetails from 'app/entities/funder-details/funder-details.reducer';
import loanDetails from 'app/entities/loan-details/loan-details.reducer';
import funderLoanContribution from 'app/entities/funder-loan-contribution/funder-loan-contribution.reducer';
import termLoanProfile from './term-loan-profile/term-loan-profile.reducer';
import termLoanSchedule from './term-loan-schedule/term-loan-schedule.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  funder,
  loanType,
  loanProduct,
  loan,
  loanFunder,
  loanDisbursement,
  loanRepayment,
  borrower, 
  funderDetails,
  loanDetails,
  funderLoanContribution,
  generalLedger,
  termLoanProfile,
  termLoanSchedule

  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
