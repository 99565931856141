import React, { useState } from 'react';
import { Form, useForm } from 'react-hook-form';
import { ValidatedField } from 'react-jhipster';
import { Button, Col, Row, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { createEntity } from './account-creation-reducer';
import { useDispatch } from 'react-redux';
const AccountCreation = () => {

  const { register, control, getValues } = useForm();
  const [response, setResponse] = useState('');
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const values = getValues();
    const entity = {
      account_number: values.account_number,
      account_name: values.account_name,
      unique_id: values.unique_id,
      account_type: values.account_type,
      currency: values.currency,
      account_balance: values.account_balance,
      entity_type: values.entity_type,
      real_bank_iban: values.real_bank_iban,
    };

    const result = await dispatch<any>(createEntity(entity));
    try {
      if (result.payload !== undefined) {
        setResponse('Account Added Successfully');
      } else {
        setResponse('Account Adding Failed');
      }
    } catch (error) {
      setResponse("Something went wrong")
    }
    setShowModal(true);
  };
  const handleOk = () => {
    setShowModal(false);
  };
  return (
    <div>
      <h2 className='bg-dark'>GL Account Creation</h2>
      <Form control={control} onSubmit={handleSubmit}>
        <Row>
          <Col md="4">
            <ValidatedField
              label="Unique ID"
              id="unique_id"
              type="text"
              name="unique_id"
              placeholder="Unique ID"
              register={register}
              validate={{
                required: { value: true, message: 'This field is required.' },
              }}
            />
            <ValidatedField
              label="Currency"
              name="currency"
              id="currency"
              type="select"
              placeholder="Currency"
              register={register}
              validate={{
                required: { value: true, message: 'This field is required.' },
              }}
            >
              <option value="Debit">Euros</option>
              <option value="Credit">Pound</option>
            </ValidatedField>
            <ValidatedField
              label="Real Bank Iban"
              id="real_bank_iban"
              type="text"
              name="real_bank_iban"
              placeholder="Real Bank Iban"
              register={register}
            />
          </Col>
          <Col md="4">
            <ValidatedField
              label="Account Name"
              id="account_name"
              type="text"
              name="account_name"
              placeholder="Account Name"
              register={register}
              validate={{
                required: { value: true, message: 'This field is required.' },
              }}
            />
            <ValidatedField
              label="Account Balance"
              id="account_balance"
              type="number"
              name="account_balance"
              register={register}
              placeholder="Account Balance"
              validate={{
                required: { value: true, message: 'This field is required.' },
              }}
            />

          </Col>
          <Col md="4">
            <ValidatedField
              label="Account type"
              name="account_type"
              id="account_type"
              type="select"
              placeholder="Account type"
              register={register}
              validate={{
                required: { value: true, message: 'This field is required.' },
              }}
            >
              <option value="Debit">Savings</option>
              <option value="Credit">Current</option>
            </ValidatedField>
            <ValidatedField
              label="Entity Type"
              name="entity_type"
              id="entity_type"
              type="select"
              placeholder="Entity Type"
              register={register}
              validate={{
                required: { value: true, message: 'This field is required.' },
              }}
            >
              <option value="Debit">Flender</option>
              <option value="Credit">Funder</option>
            </ValidatedField>

          </Col>
        </Row>
        <div className="d-flex flex-row-reverse">
          <Button
            type="submit"
            className='customButton'
          >
            Create
          </Button>
        </div>
      </Form>
      <div>
        <Modal isOpen={showModal}>
          <ModalBody>
            <p>{response}</p>
          </ModalBody>
          <ModalFooter>
            <Button className='customButton' onClick={handleOk}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </div>

    </div>
  );
};

export default AccountCreation;
