import './home.scss';
import React, { useCallback, useState } from 'react';
import { Row, Col, Button, NavItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GeneralLedger from 'app/entities/general-ledger/general-ledger';
import LoanRepayment from 'app/entities/loan-repayment/loan-repayment';
import LoanProfile from 'app/entities/loan-profile/loan-profile';
import LoanFunder from 'app/entities/loan-funder/loan-funder';
import LoanStatus from 'app/entities/loan-status/loan-status';
import Reporting from 'app/entities/transaction-posting/transaction-posting';
import Borrower from 'app/entities/borrower/borrower';
import { useAppDispatch } from 'app/config/store';
import { fetchUrl } from './home.reducer';
import TermLoanProfile from 'app/entities/term-loan-profile/term-loan-profile';
import TermLoanSchedule from 'app/entities/term-loan-schedule/term-loan-schedule';
import LoanDetails from 'app/entities/loan-details/loan-details';
import Loan from 'app/entities/loan/loan';

export const Home = () => {
  const [activeMenu, setActiveMenu] = useState('RBLLoan');
  const [selectedContent, setSelectedContent] = useState('GeneralLedger');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const dispatch = useAppDispatch();

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    if (menu === 'TermLoan') {
      setSelectedContent('TermLoanProfile');
    } else if (menu === 'RBLLoan') {
      setSelectedContent('GeneralLedger');
    }
  };

  const handleNfUserTokenButtonClick = useCallback(async () => {
    try {
      const action = await dispatch(fetchUrl());
      const response = action.payload;
      window.open(response);
    } catch (error) {
      console.error('Error fetching URL:', error);
    }
  }, [dispatch]);

  const handleContentClick = (content) => {
    setSelectedContent(content);
  };

  const renderRBLLoanMenu = () => (
    <div className={`flurish-sidebar ${isSidebarOpen ? 'open' : 'collapsed'}`}>
      <NavItem
        onClick={() => handleContentClick('GeneralLedger')}
        className={selectedContent === 'GeneralLedger' ? 'active' : ''}
      >
        <div className="d-flex align-items-center" title="General Ledger">
          <FontAwesomeIcon icon="file-lines" className="sideBarCustomColor" />
          <span className="sideBarCustomColor">General Ledger</span>
        </div>
      </NavItem>

      <NavItem
        onClick={() => handleContentClick('RepaymentReports')}
        className={selectedContent === 'RepaymentReports' ? 'active' : ''}
      >
        <div className="d-flex align-items-center" title="Repayment History">
          <FontAwesomeIcon icon="tasks" className="sideBarCustomColor" />
          <span className="sideBarCustomColor">Repayment Reports</span>
        </div>
      </NavItem>

      <NavItem
        onClick={() => handleContentClick('LoanProfile')}
        className={selectedContent === 'LoanProfile' ? 'active' : ''}
      >
        <div className="d-flex align-items-center" title="Loan Profile">
          <FontAwesomeIcon icon="chalkboard" className="sideBarCustomColor" />
          <span className="sideBarCustomColor">Loan Profile</span>
        </div>
      </NavItem>

      <NavItem
        onClick={() => handleContentClick('LenderProfile')}
        className={selectedContent === 'LenderProfile' ? 'active' : ''}
      >
        <div className="d-flex align-items-center" title="Lender Profile">
          <FontAwesomeIcon icon="wallet" className="sideBarCustomColor" />
          <span className="sideBarCustomColor">Lender Profile</span>
        </div>
      </NavItem>

      <NavItem
        onClick={() => handleContentClick('LoanStatus')}
        className={selectedContent === 'LoanStatus' ? 'active' : ''}
      >
        <div className="d-flex align-items-center" title="Loan Status">
          <FontAwesomeIcon icon="bars-progress" className="sideBarCustomColor" />
          <span className="sideBarCustomColor">Loan Status</span>
        </div>
      </NavItem>

      <NavItem
        onClick={() => handleContentClick('LoanReports')}
        className={selectedContent === 'LoanReports' ? 'active' : ''}
      >
        <div className="d-flex align-items-center" title="Loan Reports">
          <FontAwesomeIcon icon="th-list" className="sideBarCustomColor" />
          <span className="sideBarCustomColor">Loan Reports</span>
        </div>
      </NavItem>

      <NavItem
        onClick={() => handleContentClick('ManualLedgerPosting')}
        className={selectedContent === 'ManualLedgerPosting' ? 'active' : ''}
      >
        <div className="d-flex align-items-center" title="Manual Ledger Posting">
          <FontAwesomeIcon icon="file-export" className="sideBarCustomColor" />
          <span className="sideBarCustomColor">Manual Ledger Posting</span>
        </div>
      </NavItem>

      <NavItem
        onClick={() => handleContentClick('BorrowerList')}
        className={selectedContent === 'BorrowerList' ? 'active' : ''}
      >
        <div className="d-flex align-items-center" title="Borrower List">
          <FontAwesomeIcon icon="users-line" className="sideBarCustomColor" />
          <span className="sideBarCustomColor">Borrower List</span>
        </div>
      </NavItem>
      <NavItem
        onClick={() => handleNfUserTokenButtonClick()}
      >
        <div className='nf-token-btn'>
          <FontAwesomeIcon icon="sign-in-alt" className="sideBarCustomColor" />
          <span className="sideBarCustomColor">{`NF User Token Login / Update`}</span>
        </div>
      </NavItem>
    </div >
  );

  const renderTermLoanMenu = () => (
    <div className={`flurish-sidebar ${isSidebarOpen ? 'open' : 'collapsed'}`}>
      <NavItem
        onClick={() => handleContentClick('TermLoanProfile')}
        className={selectedContent === 'TermLoanProfile' ? 'active' : ''}
      >
        <div className="d-flex align-items-center" title="Term Loan Profile">
          <FontAwesomeIcon icon="chalkboard" className="sideBarCustomColor" />
          <span className="sideBarCustomColor">Term Loan Profile</span>
        </div>
      </NavItem>

      <NavItem
        onClick={() => handleContentClick('TermLoanSchedule')}
        className={selectedContent === 'TermLoanSchedule' ? 'active' : ''}
      >
        <div className="d-flex align-items-center" title="Term Loan Schedule">
          <FontAwesomeIcon icon="tasks" className="sideBarCustomColor" />
          <span className="sideBarCustomColor">Term Loan Schedule</span>
        </div>
      </NavItem>
    </div>
  );

  const renderContent = () => {
    if (selectedContent === 'GeneralLedger') return <GeneralLedger />;
    if (selectedContent === 'RepaymentReports') return <LoanRepayment />;
    if (selectedContent === 'LoanProfile') return <LoanProfile />;
    if (selectedContent === 'LenderProfile') return <LoanFunder />;
    if (selectedContent === 'LoanStatus') return <LoanStatus />;
    if (selectedContent === 'LoanReports') return <Loan />;
    if (selectedContent === 'ManualLedgerPosting') return <Reporting />;
    if (selectedContent === 'BorrowerList') return <Borrower />;
    if (selectedContent === 'TermLoanProfile') return <div><TermLoanProfile /></div>;
    if (selectedContent === 'TermLoanSchedule') return <div><TermLoanSchedule /></div>;

    return <div> </div>;
  };

  return (
    <div className="main-area-container">
      <div className='d-flex'>
        <div >
          <Button
            className={`customButton ${activeMenu === 'RBLLoan' ? 'rbl-loan-active' : ''}`}
            onClick={() => handleMenuClick('RBLLoan')}
          >
            RBL Loan
          </Button>
        </div>
        <div>
          <Button
            className={`customButton ms-4 ${activeMenu === 'TermLoan' ? 'term-loan-active' : ''}`}
            onClick={() => handleMenuClick('TermLoan')}
          >
            Term Loan
          </Button>
        </div>
      </div>

      <div className="mt-4">
        <Row>
          <Col md="2" className="flurish-left-sidebar">
            {activeMenu === 'RBLLoan' && renderRBLLoanMenu()}
            {activeMenu === 'TermLoan' && renderTermLoanMenu()}
          </Col>
          <Col md="10" className="content-area">
            <div className="p-3">{renderContent()}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
